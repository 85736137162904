/* @flow */

module.exports = {
  "APP": {
    "NAME": "Sector Alarm Webshop",
  },
  "LOCALE": {
    "ISO": {
      "639-1": "en",
      "3166-1": "GB",
      "IETF": "en-GB",
    },
    "CURRENCY": "EUR",
    "CURRENCY_SHORT": "€",
    "POINTS": "points",
  },
  "META": {
    "GOOGLE_SITE_OWNER": "",
    "HELPDESK_LINK": "https://helpdesk.crossroads.se/?s=1190ff2f5db418c260b64c8f95cd3c7a",
  },
  "LOGO": {
    "ALT": "Sector Alarm",
    "TITLE": "Go to home page",
    "SUFFIX": "shop",
  },
  "COOKIE_CONSENT": {
    "TEXT": "To enhance and customize your experience of our digital services, we use cookies. Read our <a href=\"/privacy-policy\">Privacy Policy</a> if you want to learn more about cookies.",
    "ALL": "Accept all",
    "SELECTED": "Accept selected",
    "NECESSARY": "Accept necessary",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Necessary",
    "STATISTICAL": "Statistical",
    "MARKETING": "Marketing",
  },
  "CURRENCY_SHORT": {
    "NOK": "kr",
    "DKK": "kr",
    "SEK": "kr",
    "EUR": "€",
  },
  "BREADCRUMBS": {
    "HOME": "Home",
    "HOME_URL": "/",
  },
  "HOMEVIEW": {
    "POPULAR_CATEGORIES": "Popular categories",
    "SEE_MORE_POPULAR_CATEGORIES": "View more categories",
    "POPULAR_PRODUCTS": "Popular products",
    "SEE_MORE_POPULAR_PRODUCTS": "View more popular products",
    "RECENTLY_VIEWED_PRODUCTS": "Recently viewed products",
    "ORDER_NOW": "Order now",
    "GO_TO": "Go to category",
  },
  "ALL_PRODUCTS": {
    "TITLE": "All products",
  },
  "SORT": {
    "OFFCANVAS": {
      "TITLE": "Sort",
      "SHOW_RESULT": "Show result",
      "CLOSE_MENU": "Close menu",
    },
  },
  "FILTER": {
    "FILTERBAR": {
      "ALL_FILTERS": "All filters",
      "FILTER_BY_POINTS": "Filter by points",
      "FILTER_BY_PRICE": "Filter by price",
      "PRICE_RANGE": "%min - %max points",
      "SORT": "Sort",
      "SORT_DEFAULT": "Best match",
      "SORT_PRICE_DESC": "Price ↓",
      "SORT_PRICE_ASC": "Price ↑",
      "SORT_NAME_DESC": "A-Z ↓",
      "SORT_NAME_ASC": "A-Z ↑",
    },
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT_TOTAL_SINGLE": "%total Product",
      "SHOW_RESULT_TOTAL_MULTIPLE": "%total Products",
      "SHOW_RESULT": "Show result",
      "CLOSE_MENU": "Close menu",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Clear filter",
      "NO_ACTIVE_FILTERS": "You have no active filters",
      "PRICE_RANGE": "Price (%range)",
      "TOTAL_COUNT": "Viewing %count products",
    },
    "FILTERMENU": {
      "FILTER_BY_POINTS": "Filter by points",
      "FILTER_BY_PRICE": "Filter by price",
      "RESET_PRICE": "Clear",
      "PRICE_FROM": "From",
      "PRICE_TO": "To",
      "BALANCE_FILTER": "Filter by current balance (%value points)",
    },
  },
  "ADDRESS": {
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "STREET_1": "Street address 1",
    "STREET_2": "Street address 2",
    "POSTCODE": "Postal code",
    "CITY": "City",
    "COUNTRY": "Country",
    "EMAIL": "E-mail",
    "TELEPHONE": "Phone number",
  },
  "OR": "or",
  "CART": {
    "HEADING": "Cart",
    "EMPTY": "Your cart is empty",
    "CTA": "Go to checkout",
    "OF": "of",
    "ALT": "Edit cart contents",
    "EDIT": "Edit cart contents",
    "INCREMENT": "Increase quantity",
    "DECREMENT": "Decrease quantity",
    "QUANTITY": "Quantity",
    "SHIPPING": "Shipping",
    "SUBTOTAL": "Subtotal",
    "GRANDTOTAL": "Grand total",
    "SUMMARY": "Summary",
    "TO_CHECKOUT": "Go to checkout",
    "REMOVE_PRODUCT": "Remove item",
    "REMOVE_APPROVE": "Yes, remove this item",
    "CONFIRM_TEXT": "Are you sure you'd like to remove '%itemName' from  your cart?",
    "MINIMIZE_SUMMARY": "Minimize summary",
    "VIEW_DETAILS": "View details",
  },
  "CONFIRM_DIALOG": {
    "CANCEL": "Cancel",
  },
  "CHECKOUT": {
    "NEED_HELP": "Need help? We're here for you!",
    "CONTACT_CUSTOMER_SERVICE": "Contact customer service",
    "TO_PAYMENT": "Go to payment",
    "CHOOSE_PAYMENT_METHOD": "Proceed to payment",
    "AMOUNT_TO_PAY": "Amount to be withdrawn",
    "CASH": "Cash",
    "PAY_WITH_CARD": "Pay using card",
    "CARD_PAYMENT": "Pay using card",
    "EDIT": "Change",
    "PAYMENT_AND_DELIVERY": "Payment & Delivery",
    "SENT_TO": "To be sent to",
    "CONFIRM_ORDER": "Confirm order",
    "CONTINUE_SHOPPING": "Continue shopping",
    "PAYMENT_TITLE": "How would you like to pay?",
    "I_ACCEPT_THE": "I accept the",
    "TERMS": "terms for this purchase",
    "ACCORDION_HEADER_CART": "Cart",
    "DISCOUNT_CODE": {
      "HEADER": "Discount code",
      "CHECKBOX_LABEL": "I have a discount code",
      "APPLY": "Apply",
      "REMOVE": "Remove",
    },
    "ADDRESS": {
      "ADDRESS": "Address",
      "SHIPPING": "Delivery address",
      "BILLING": "Address on receipt",
      "SHIP_TO_SAME_ADDRESS": "Same address on reciept",
      "EMPTY_ADDRESS": "Please enter a valid address",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Cart",
        "LONG": "Cart",
      },
      "SHIPPING": {
        "SHORT": "Delivery",
        "LONG": "Check your adress",
      },
      "PAYMENT": {
        "SHORT": "Allocate payment",
        "LONG": "Allocate your payment",
      },
      "OVERVIEW": {
        "SHORT": "Pay",
        "LONG": "Overview",
      },
    },
  },
  "PAYMENT": {
    "USE_CARD": "Pay with card",
    "USE_SAVED_CARD": "Pay with saved card",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "CUSTOMER_SERVICE": {
    "TEXT": "Customer service",
    "LINK": "/customersupport",
  },
  "SUCCESS": {
    "TITLE": "Order completed",
    "SUB_TITLE": "Order id %number",
    "NOT_FOUND_TITLE": "No order found.",
    "NOT_FOUND_SUBTITLE": "Please contact customer service if this is incorrect.",
    "BACK": "Back to the store",
    "VALID_UNTIL": "Valid until %date",
    "WHAT_NOW": "What's next?",
    "ORDER_OVERVIEW": "Order overview",
    "CONTACT": {
      "TITLE": "Got a question?",
      "CREATE_TICKET": "Send us a message",
      "INFO": "Do you have a question about a product or about your order? Do not hesitate to contact us.",
      "CALL_US": "Call us",
      "CALL_US_HOURS": "09.00-11.00, on weekdays",
      "CALL_US_PHONENUMBER": "+46 (0)31 700 83 86",
    },
    "STEPS": {
      "NORMAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "Delivery is done to the pickup point closest to the address you have provided. As soon as the parcel is ready for pickup you will be notified via text message and/or email. The parcel will remain at the pickup point for a maximum of 14 days before being returned to us.",
        },
      },
      "MIXED": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation, as well as the voucher codes you've ordered, will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "Delivery is done to the pickup point closest to the address you have provided. As soon as the parcel is ready for pickup you will be notified via text message and/or email. The parcel will remain at the pickup point for a maximum of 14 days before being returned to us.",
        },
      },
      "VIRTUAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Digital delivery",
          "TEXT": "Any voucher codes you've ordered are displayed here on the receipt page. We'll also send them to %email shortly. If you haven't received an email within one hour of placing the order, we ask you to kindly double check your spam filter before contacting customer service.",
        },
      },
    },
  },
  "SERIAL_CODE": {
    "FETCH_STATUS": {
      "LOADING": "Please wait while we fetch your voucher code",
      "ERROR": "An error occured. Your code is sent to your e-mail",
    },
  },
  "CATEGORY": {
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viewing 1 product",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viewing %itemsCount products",
    "VIEW_ALL": "View all",
    "SUBCATEGORIES": "Sub categories",
  },
  "PRODUCTLIST": {
    "EMPTY": "No products to show",
  },
  "POPULAR": {
    "POPULAR_PRODUCTS": "Popular products",
  },
  "404": {
    "TITLE": "Sorry, this page could not be found.",
    "HEADING": "Sorry, this page could not be found.",
    "SUBHEADING": "Sorry, this page could not be found.",
  },
  "SERVER_ERROR": {
    "TITLE": "Server error",
    "HEADING": "Server error: 500",
    "SUBHEADING": "We apologise – please return in a while.",
    "TEXT": "<strong>Need to get in touch?</strong><br/>Contact us via our <a href=\"mailto:info@awardit.com\">info@awardit.com</a>.",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Search: ",
    "PLACEHOLDER": "Search the assortment",
    "RESULTS": "Search results for \"%query\"",
    "TYPE": {
      "CATEGORY": "Category",
      "PRODUCT": "Product",
      "BRAND": "Brand",
    },
  },
  "HEADER": {
    "NAVIGATION": "Navigation",
    "SEARCH": "Search",
    "CATEGORIES": "Categories",
    "BRANDS": "Brands",
    "CART": "Checkout",
    "SECTOR": {
      "TITLE": "Go to phonewatch.ie",
      "URL": "https://www.phonewatch.ie",
    },
  },
  "FOOTER": {
    "LINKS": {
      "TITLE": "Links",
      "START": "Home",
      "CATEGORIES": "Categories",
      "BRANDS": "Brands",
      "SEARCH": "Search products",
    },
    "NEED_HELP": {
      "TITLE": "Do you need help?",
      "CONTACT": "Contact us",
      "COOKIE_POLICY": "Cookie Policy",
      "PRIVACY": "Privacy Policy",
      "COOKIES": "Manage cookies",
    },
    "STAY_IN_TOUCH": {
      "TITLE": "Keep in touch",
      "FACEBOOK": "Facebook",
      "INSTAGRAM": "Instagram",
      "YOUTUBE": "Youtube",
      "TWITTER": "Twitter",
      "LINKEDIN": "Linkedin",
      "NEWSLETTER": "Subscribe to newsletter",
    },
    "COPYRIGHT": "Copyright © %year <a href=\"https://sectoralarm.com\" target=\"_blank\" rel=\"noopener\">Sector Alarm</a>",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Add to cart",
    "OUT_OF_STOCK": "Out of stock",
    "OTHERS_ALSO_LIKED": "Other items you might be interested in",
    "OPTION_DROPDOWN_PLACEHOLDER": "Please select",
    "CHANGE_IMAGE": "Change image",
    "GO_TO_MANUFACTURER": "View all products from %brand",
    "ABOUT_BRAND": "About %brand",
    "COLOR": "Color:",
    "CHOOSE_A_COLOR": "Pick a color",
    "DESCRIPTION": "Product description",
    "SALE": "Sale",
    "MSRP": "List price",
    "READ_MORE": "Read more",
  },
  "COUPON_LIGHTBOX": {
    "TITLE": "Welcome!",
    "INFO": "We have added your coupon code automatically, so you don't have to fill it in manually in the checkout, nice huh?",
    "CTA": "Start shopping",
  },
  "STRIPE": {
    "PROCESSING": "Loading...",
    "CARD_NUMBER": "Card number",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
  },
  "VALIDATION": {
    "REQUIRED": "*",
    "EMAIL": "Please enter a valid email address.",
    "errorInvalidEmail": "Please enter a valid email address.",
    "errorEmailExists": "This email is already in use by another account.",
    "PHONE": "Please enter a valid phone number.",
    "NUMERIC": "This is a numeric field.",
    "LENGTH_LT": "This field contains too many characters.",
    "POSTCODE": "Please enter a valid postal code.",
  },
  "STATUS_CODE": {
    "UNKNOWN": "An unknown error occured. Please reload the page and try again.",
    "NETWORK_ERROR": "The network call was canceled. Please verify that you have an active internet connection and try again.",
    "PLACE_ORDER_CATCH": "An unknown error occured. Please try again later.",
    "errorUnknownProduct": "Unknown product. Please try again.",
    "errorNoPaymentMethod": "No payment method selected.",
    "errorProductQuantityNotAvailable": "The requested quantity is not currently available.",
    "errorProductNotInStock": "This product is currently out of stock.",
    "errorProductMaxQuantity": "We don't have the requested quantity in stock.",
    "errorProductNotChosenVariant": "Please select %attributeLabel in order to place this item in your cart.",
    "termsNotAccepted": "You must approve the terms and conditions to complete your order.",
    "errorNotLoggedIn": "You're not logged in. Please log in again.",
    "errorInvalidState": "An unknown error occured. Please try again.",
    "errorInvalidLogin": "Invalid login details.",
    "errorMissingCustomer": "The customer you are trying to login as does not exist.",
    "errorBlacklistedEmail": "This email is already in use by another account. Please use another one.",
    "errorInvalidEmail": "The email address is not correct, please check it.",
    "errorEmailExists": "This email is already in use by another account. Please use another one.",
    "errorInvalidEmailOrPassword": "Invalid login details.",
    "Your card number is incomplete": "Please enter your card details.",
    "SUCCESSFULLY_LOGGED_IN": "Logged in",
    "LOGIN_ERROR": "The log-in failed. Please try again or wait and try again in a couple of minutes.",
    "YOUR_CARD_HAS_INSUFFICIENT_FUNDS": "Insufficient funds on card.",
    "YOUR_CARDS_SECURITY_CODE_IS_INCORRECT": "The CVC code for this card is invalid.",
    "YOUR_CARD_WAS_DECLINED": "The card was declined.",
    "YOUR_CARD_HAS_EXPIRED": "The card has expired.",
    "AN_ERROR_OCCURRED_WHILE_PROCESSING_YOUR_CARD_TRY_AGAIN_IN_A_LITTLE_BIT": "An error occurred. Please try again later",
    "WE_ARE_UNABLE_TO_AUTHENTICATE_YOUR_PAYMENT_METHOD_PLEASE_CHOOSE_A_DIFFERENT_PAYMENT_METHOD_AND_TRY_AGAIN": "We could't authenticate your payment method. Please select an alternative payment method (if applicable) and try again.",
    "INTERNAL_SERVER_ERROR": "Internal server error.",
    "CODE_COPIED": "The voucher code has been copied to your clipboard",
    "CODE_NOT_COPIED": "The voucher code could not be copied",
    "errorMaxAttemptsReached": "Too many calls. Please wait a few minutes and try again.",
    "errorPointRedemptionNotAllowed": "Your account is unable to redeem points at the moment.",
    "invalid_sso_token_or_otp": "Felaktig OTP. Vänligen försök igen",
    "invalid_sso_ticket": "Your session timed out. Please login again.",
    "errorNoTicket": "Your session timed out. Please login again.",
    "ITEM_REMOVED_FROM_CART": "%name was removed from your cart since it is no longer in stock.",
    "DISCOUNT_CODE_APPLIED": "Discount code applied.",
    "DISCOUNT_CODE_FAILURE": "The discount code could not be applied. Please contact customer service if the problem persists.",
    "BILLING_ADDRESS_ERROR": "Correct delivery details are missing, please update your information.",
  },
};
